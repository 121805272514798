body {
  font-family: sans-serif;
  background-color: #673ab7;
  color: white;
  overflow-y: visible;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

svg.circularProgressBar {
  position: absolute;
  transform: rotate(-90deg);
  animation: offsettozero linear forwards;
  animation-iteration-count: infinite;
}
@keyframes offsettozero {
  to {
    stroke-dashoffset: 0;
  }
}
